import React, { useState, useEffect } from "react";
import "./Land.css";
import githubLogo from "./../../assets/github.svg";
import linkedinLogo from "./../../assets/linkedin.svg";
import gifSrc from "./../../assets/portfolioGif.gif";
import downArrow from "./../../assets/downward_arrow.png";
import Project from "../ProjectPage/Project.js";
import Experience from "../ExperiencePage/Experience.js";
import Footer from "../Footer/Footer.js";

function Land() {
  const developerTypes = [
    "Frontend Developer",
    "Backend Developer",
    "Full Stack Developer",
    "Cloud Developer",
  ];
  const resume = '/Yogish_Resume.pdf';
  const [index, setIndex] = useState(0);

  function navigateToProject() {
    const targetElement = document.getElementById('projecthead');
    if (targetElement) {
        const topOffset = targetElement.getBoundingClientRect().top - (window.innerHeight * 0.07);
        console.log("hello");
        window.scrollTo({
            top: window.scrollY + topOffset,
            behavior: 'smooth'
        });
    }
}
  const downloadResume = () => {
    const link = document.createElement('a');
    link.href = resume;
    link.download = 'Yogish_Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % developerTypes.length);
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [developerTypes.length]);

  return (
    <>
    <div className="landingmaindiv">
      <div className="firstdiv">
        <div>
          <h2 id="hi-para">Hi, My name is </h2>
        </div>
        <div>
          <h1 className="nametag">Yogish Honnadevipura Gopalakrishna </h1>
        </div>
        <div>
          <h2 className="fade-in-out">I am a {developerTypes[index]}</h2>
        </div>
        <div>
          <button id="resumebutton" onClick={downloadResume}>
              Resume
          </button>
        </div>
        <div className="social-links">
          <a
            href="https://github.com/Yogish-HG/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={githubLogo} alt="GitHub" className="social-logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/yogishsoftwaredev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinLogo} alt="LinkedIn" className="social-logo" />
          </a>
        </div>
      </div>
      <div className="seconddiv" id="workGIF">
        <img src={gifSrc} alt="Animated GIF" className="gif" />
      </div>
      <div id="downarrow">
        <img src={downArrow} alt="down" className="downlogo" onClick={navigateToProject}/>
      </div>
    </div>

    <Project className='project-div'/>
    <Experience/>
    <Footer/>
    </>
    
    
  );
}

export default Land;
