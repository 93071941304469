import React from 'react';
import Carasoul from "./ProjectPage/Carasoul.js";

const SingleProject = (props) => {

    function gotowebgit() {
        window.open(props.git, "_blank");
    }
    
    function gotoweblive() {
        window.open(props.live, "_blank");
    }

    return (
        props.right ?
            <div className="individualproject">
                <div className="photodiv">
                    <Carasoul images={props.images}> </Carasoul>
                </div>
                <div className="descriptiondiv">
                    <h1 className="projtitle">{props.title}</h1>
                    <h2 className="projtitle">{props.subtitle}</h2>
                    <p className="desc">
                        {props.children}
                    </p>
                    {props.isgit && <div className="projtitle git" onClick={gotowebgit}>
                        GIT
                    </div>}

                    {props.islive && <div className="projtitle live" onClick={gotoweblive}>
                        LIVE
                    </div>}
                </div>
            </div>
            :
            <div className="individualproject" id="right">
                <div className="photodiv">
                    <Carasoul images={props.images}> </Carasoul>
                </div>
                <div className="descriptiondiv">
                    <h1 className="projtitle titletoleft">{props.title}</h1>
                    <h2 className="projtitle titletoleft">{props.subtitle}</h2>
                    <p className="desc titletoleft">
                        {props.children}
                    </p>
                    {props.isgit && <div className="git left" onClick={gotowebgit}>
                        GIT
                    </div>}
                    {props.live &&<div className="projtitle live left" onClick={gotoweblive}>
                        GO LIVE
                    </div>}
                </div>
            </div>
    );
}


export default SingleProject;