import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-main">
      <h3 className="made-by"> Made with ♥ by Yogish </h3>
      <div className="social">
        <h3>
          <a
            href="https://www.linkedin.com/in/yogish-honnadevipura-gopalakrishna-2972b91b4/"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin"
          >
            LinkedIn
          </a>
        </h3>
        <h3>
          {" "}
          <a
            href="https://github.com/Yogish-HG/ "
            target="_blank"
            rel="noopener noreferrer"
            className="github"
          >
            Github
          </a>
        </h3>
      </div>
    </div>
  );
}

export default Footer;
