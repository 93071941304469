import React from "react";
import "./Project.css";
import SingleProject from "../SingleProject.js";
import contactUsImage from "./../../assets/web/contact-us page.png";
import loginImage from "./../../assets/web/Login.png";
import cartImage from "./../../assets/web/cart.png";
import booksImage from "./../../assets/web/Books.png";

import adminasdc from "./../../assets/asdc/admin.png";
import applicationsasdc from "./../../assets/asdc/applications.png";
import filteringasdc from "./../../assets/asdc/Filtering.png";
import passwordasdc from "./../../assets/asdc/password.png";

import loginsless from "./../../assets/Serverless/slesslogin.png";
import hotelsless from "./../../assets/Serverless/slesshotels.png";
import firebasesless from "./../../assets/Serverless/slessfirebase.png";

import costcloud from "./../../assets/Cloud/cost.png";
import archcloud from "./../../assets/Cloud/archcloud.png";

import portfolioLand from "./../../assets/this/landPage.png";
import portfoliocskills from "./../../assets/this/skills.png";
import portfoliocontact from "./../../assets/this/contact.png";
import portfolioproject from "./../../assets/this/project.png";

function Project() {
  const webimages = [contactUsImage, loginImage, cartImage, booksImage];
  const asdcimages = [adminasdc, applicationsasdc, filteringasdc, passwordasdc];
  const serverlessimages = [loginsless, hotelsless, firebasesless];
  const cloudimages = [archcloud, costcloud];
  const portfolioimages = [
    portfolioLand,
    portfoliocskills,
    portfoliocontact,
    portfolioproject,
  ];

  return (
    <div className="project-div">
      <h1 id="projecthead" className="head">
        PROJECTS
      </h1>
      <div className="projectcontainer">
        <SingleProject
          right={true}
          isgit={true}
          islive={true}
          live={"https://bookstack-csci-group-13.netlify.app/"}
          git={"https://github.com/Yogish-HG/MERNWebApp.git"}
          images={webimages}
          title={"Bookstack"}
          subtitle={"Digital Library App"}
        >
          Book Stack is a web application built using{" "}
          <span className="keyword"> MERN Stack </span> designed to
          revolutionize how we interact with books and libraries by digitalizing
          library systems. It enhances accessibility, promotes reading
          culture, and fosters a community where book lovers can share their
          experiences. Book Stack uses <span className="keyword">React</span>{" "}
          for the UI,
          <span className="keyword"> MongoDB</span> for data management,
          <span className="keyword"> NodeJS</span> and{" "}
          <span className="keyword"> ExpressJS </span>
          for fast, scalable REST APIs.
        </SingleProject>

        <SingleProject
          right={false}
          islive={false}
          isgit={true}
          live={""}
          git={"https://github.com/Yogish-HG/React-SpringBoot-MySql-App.git"}
          images={asdcimages}
          title={"Accomatch"}
          subtitle={"Accommodation Finder App"}
        >
          Accomatch is an application designed to streamline the process of
          finding and securing accommodations, catering to travelers, students,
          and professionals. It offers a user-friendly interface for browsing
          and selecting accommodations based on personalized preferences. The
          Application is built with <span className="keyword">React</span>,{" "}
          <span className="keyword">Spring Boot</span>,{" "}
          <span className="keyword">MySQL</span>, and tested using{" "}
          <span className="keyword">JUnit</span>.
        </SingleProject>

        <SingleProject
          right={true}
          islive={false}
          isgit={true}
          live={""}
          git={"https://github.com/Yogish-HG/Serverless.git"}
          images={serverlessimages}
          title={"Dine'n'Discover"}
          subtitle={"Hotel Reservation App"}
        >
          The Halifax Dine’n’Discover Table Reservation App uses a serverless
          architecture on Google Cloud Platform (GCP) and Amazon Web Services
          (AWS). The <span className="keyword">React</span> responsive frontend
          is supported by <span className="keyword">Python</span> and{" "}
          <span className="keyword">Javascript</span> backend{" "}
          <span className="keyword">lambda functions</span>, with{" "}
          <span className="keyword">Firestore</span> and{" "}
          <span className="keyword">DynamoDB</span> managing content.{" "}
          <span className="keyword">Firebase Authentication</span> provides
          secure user access, and{" "}
          <span className="keyword">Amazon API Gateway</span> handles RESTful
          APIs.
        </SingleProject>

        <SingleProject
          right={false}
          islive={false}
          isgit={true}
          live={""}
          git={"https://github.com/Yogish-HG/Cloud.git"}
          images={cloudimages}
          title={"ShopVista"}
          subtitle={"Online Groceries App"}
        >
          The E-Commerce Grocery and Food Delivery Web Application is a
          comprehensive online platform designed for users to conveniently
          purchase groceries and food products. The project uses AWS services
          like <span className="keyword">Elastic Beanstalk</span> and AWS Lambda
          for computing, <span className="keyword">DynamoDB</span> and{" "}
          <span className="keyword">S3</span> for storage,{" "}
          <span className="keyword">API Gateway</span> for networking, and{" "}
          <span className="keyword">SNS</span> for messaging. It employs
          Infrastructure as Code with{" "}
          <span className="keyword">AWS CloudFormation</span> for managing its
          infrastructure.
        </SingleProject>

        <SingleProject
          right={true}
          islive={false}
          isgit={false}
          live={""}
          git={""}
          images={portfolioimages}
          title={"Personal Portfolio"}
          subtitle={"this.site || self.site"}
        >
          TThis portfolio is built using React with JavaScript and CSS,
          featuring three pages: <span className="keyword">Home</span>,{" "}
          <span className="keyword">About</span>, and{" "}
          <span className="keyword">Contact</span>. APIs.
        </SingleProject>
      </div>
    </div>
  );
}

export default Project;
