import React, { useState, useEffect } from "react";
import { useNavigate,useLocation  } from 'react-router-dom';

import "./Navbar.css"; 

function Navbar() {
  const [isOtherLinksVisible, setIsOtherLinksVisible] = useState(false);
  const [pageloc, setpageloc] = useState("home");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

    useEffect(() => {
      const handleScroll = () => {
        const navbar = document.getElementById('desktopnav');
        if(window.innerWidth > 576){
          if (window.scrollY > 50) {
            navbar.style.top = '0px';
          } else {
            navbar.style.top = '40px';
          }
        }
        
      };
  
      window.addEventListener('scroll', handleScroll);
    const handleClickOutside = (e) => {
      if (!e.target.closest(".navbarmaindiv")) {
        setIsOtherLinksVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handlenav() {
    setIsOtherLinksVisible(!isOtherLinksVisible);
  }
  function handlehome(){
    navigate("/");
    setpageloc("home");
    if(isOtherLinksVisible){
      setIsOtherLinksVisible(!isOtherLinksVisible);
    }
  }
  function handleabout(){
    navigate("/about");
    setpageloc("about");
    if(isOtherLinksVisible){
      setIsOtherLinksVisible(!isOtherLinksVisible);
    }
  }
  function handlecontact(){
    navigate("/contact");
    setpageloc("contact");
    if(isOtherLinksVisible){
      setIsOtherLinksVisible(!isOtherLinksVisible);
    }
  }

  return (
    <div className="navbarmaindiv">
      <nav className="navbar">
        <button class="hamburger-menu" onClick={handlenav}>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </button>
      </nav>

      <nav Id="desktopnav">
        <div onClick={handlehome} className= {`desktophome ${pageloc === "home" ? "showcolor" : "hidecolor"}`}>
          <p>Home</p>
          </div>
        <div className="desktopother">
          <div  onClick={handleabout} className={`sectionclassdesktop ${pageloc === "about" ? "showcolor" : "hidecolor"}`}><p>About</p></div>
          <div  onClick={handlecontact} className={`sectionclassdesktop last ${pageloc === "contact" ? "showcolor" : "hidecolor"}`}><p>Contact</p></div>
        </div>
      </nav>
      <div className={`otherlinks ${isOtherLinksVisible ? "show" : "hide"}`}>
        <div  onClick={handlehome} className={`sectionclass ${pageloc === "home" ? "showcolor" : "hidecolor"}`}>Home</div>
        <div  onClick={handleabout} className={`sectionclass ${pageloc === "about" ? "showcolor" : "hidecolor"}`}>About</div>
        <div  onClick={handlecontact} className={`sectionclass last ${pageloc === "contact" ? "showcolor" : "hidecolor"}`}>Contact</div>
      </div>  
    </div>
  );
}

export default Navbar;
