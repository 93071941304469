import Navbar from "./components/Navbar/Navbar";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage/Land.js";
import About from "./components/AboutPage/About.js";
import Contact from "./components/ContactPage/Contact.js";
import "./App.css";

function App() {

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
