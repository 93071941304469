import React, { useState } from "react";
import emailjs from "emailjs-com";
import emailimg from "../../assets/emailimg.png";
import Swal from 'sweetalert2';
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      // setErrors(validationErrors);
      const errorMessage = Object.values(validationErrors).join('\n');
      Swal.fire(errorMessage);
      return;
    }
    emailjs
      .send(
        "service_g9nhuqt",
        "template_bgh7qh6",
        formData,
        "LU0BNjKLyFDuih0mm"
      )
      .then((response) => {
        Swal.fire("The Mail has been sent to Yogish successfully");
      })
      .catch((err) => {
        Swal.fire("The mail could not been sent due to an unforeseen error. Please try again after some time");
        console.log(err);
      });

    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };
  const validateForm = (data) => {
    const errors = {};

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = '!.Invalid Email format.';

    }
  
    if (isEmptyOrNull(data.name) || isEmptyOrNull(data.message)) {
      errors.password = '!.Name or message cannot be empty';

    }
    return errors;
  }
  function isEmptyOrNull(value) {
    return value === '' || value === null;
} 

  return (
    <div>
      <div className="contact-form">
        <h2 className="contacthead">Contact Me</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputdiv">
            <label className="label">Name</label>
            <input
              className="input"
              type="text"
              name="name"
              placeholder="Please type your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="inputdiv">
            <label className="label">Email</label>
            <input
              type="email"
              name="email"
              className="input"
              placeholder="abc@gmail.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="inputdiv">
            <label className="label">Message</label>
            <textarea
              name="message"
              className="input messagearea"
              placeholder="Please type your Message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <div className="send">
            <button type="submit" className="sendbutton">
              Send
            </button>
          </div>
        </form>
      </div>

      <div className="gifdiv2" id="workGIF">
        <img src={emailimg} alt="email GIF" className="gif2" />
      </div>
    </div>
  );
}

export default Contact;
