import React from "react";
import "./About.css";
import Footer from "../Footer/Footer";
import downArrow from "./../../assets/downward_arrow.png";
import aboutpic from "../../assets/aboutpic.jpg";
import frontend from "../../assets/cards/frontend.jpg";
import backend from "../../assets/cards/backend.webp";
import database from "../../assets/cards/database.jpg";
import programming from "../../assets/cards/programming.jpg";
import othertools from "../../assets/cards/otherdev.webp";

import reactlogo from "../../assets/FrontTech/React.png";
import reduxlogo from "../../assets/FrontTech/redux.png";
import fx from "../../assets/FrontTech/JavaFX.png";
import nextJs from "../../assets/FrontTech/nextJS.png";
import Figma from "../../assets/FrontTech/figma.png";
import bootstrapper from "../../assets/FrontTech/bootstrap.png";
import tailwind from "../../assets/FrontTech/tailwindCSS.png";
import materialui from "../../assets/FrontTech/materialUI.png";

import flask from "../../assets/BackTech/flask.png";
import springboot from "../../assets/BackTech/springboot.png";
import nodejs from "../../assets/BackTech/nodejs.png";
import expressjs from "../../assets/BackTech/express.png";
import django from "../../assets/BackTech/django.png";
import jwt from "../../assets/BackTech/JWT.png";
import rest from "../../assets/BackTech/REST.png";
import graph from "../../assets/BackTech/graph.png";

import mysql from "../../assets/database/mysql.svg";
import mongo from "../../assets/database/mongo.png";
import dynamo from "../../assets/database/dynamo.png";
import redis from "../../assets/database/redis.png";
import firestore from "../../assets/database/firestore.png";
import postgre from "../../assets/database/postgre.png";

import c from "../../assets/prgmming/C++.png";
import java from "../../assets/prgmming/java.png";
import py from "../../assets/prgmming/python.png";
import js from "../../assets/prgmming/js.webp";
import ts from "../../assets/prgmming/ts.png";
import chash from "../../assets/prgmming/chash.png";

import git from "../../assets/other/git.png";
import docker from "../../assets/other/docker.png";
import kube from "../../assets/other/kube.png";
import cicd from "../../assets/other/cicd.png";
import aws from "../../assets/other/aws.png";
import GCP from "../../assets/other/GCP.png";

function About() {
  function navigateToskills() {
    const targetElement = document.getElementById('skills');
    if (targetElement) {
        const topOffset = targetElement.getBoundingClientRect().top - (window.innerHeight * 0.07);
        window.scrollTo({
            top: window.scrollY + topOffset,
            behavior: 'smooth'
        });
    }
}
  return (
    <div>
      <div className="flexdiv">
        <div id="profileimg">
          <img src={aboutpic} alt="Profile" id="pic" />
        </div>
        <div className="aboutdiv">
          <p className="about">
            Hello, I'm Yogish, a passionate Full Stack Web Developer with nearly 2
            years of professional experience in software development. I recently completed my
           Master’s in Applied Computer Science at <a
              href="https://www.dal.ca/"
              target="_blank"
              rel="noopener noreferrer"
              className="keyword"
            >
              Dalhousie University
            </a> in
            Halifax, Canada. During my studies, I gained
            invaluable hands-on experience through a CO-OP work term at{" "}
            <a
              href="https://www.protocase.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="keyword"
            >
              Protocase
            </a>{" "}
            , where I actively contribute to real-world projects and enhance my
            professional skills. I am committed to continuously advancing my skills and
            contributing to meaningful projects in the field of computer
            science.

          </p>
        </div>
      </div>
      <div id="downarrow2">
        <img src={downArrow} alt="LinkedIn" className="downlogo" onClick={navigateToskills}/>
      </div>
      <div>
        <h1 className="head top-space" id="skills">Skills</h1>
        <div id="card-holder">
          <div className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${frontend})` }}
            ></div>
            <div className="card-content">
              <h2 className="about cardheading"> Frontend Technologies</h2>
              <div className="alllogodiv">
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${reactlogo})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    React
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${reduxlogo})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Redux
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${fx})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    JavaFX
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${nextJs})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Next.JS
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${Figma})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Figma
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${tailwind})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Tailwind
                  </p>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    CSS
                  </p>
                </div>

                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${bootstrapper})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Bootstrap
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${materialui})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    MaterialUI
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${backend})` }}
            ></div>
            <div className="card-content">
              <h2 className="about cardheading"> Backend Technologies</h2>
              <div className="alllogodiv">
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${expressjs})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Express
                  </p>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    JS
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${springboot})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Spring
                  </p>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Boot
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${nodejs})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Node.JS
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${flask})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Flask
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${django})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Django
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${jwt})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    JWT
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${rest})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    REST
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${graph})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    GraphQL
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${database})` }}
            ></div>
            <div className="card-content">
              <h2 className="about cardheading"> Database </h2>
              <div className="alllogodiv">
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${mysql})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    MySQL
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${mongo})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Mongo
                  </p>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    DB
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${dynamo})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Dynamo
                  </p>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    DB
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${redis})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Redis
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${firestore})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Firestore
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${postgre})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    PostgreSQL
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${programming})` }}
            ></div>
            <div className="card-content">
              <h2 className="about cardheading"> Languages</h2>
              <div className="alllogodiv">
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${c})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    C/C++
                  </p>
                  <br />
                  
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${java})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Java
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${py})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Python
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${chash})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    C#
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${js})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    JavaScript
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${ts})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    TypeScript
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${othertools})` }}
            ></div>
            <div className="card-content">
              <h2 className="about cardheading"> Other Tools</h2>
              <div className="alllogodiv">
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${aws})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    AWS
                  </p>
                  <br />
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${docker})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Docker
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${GCP})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    GCP
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${git})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    GIT
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${cicd})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    CICD
                  </p>
                </div>
                <div className="singlelogo">
                  <div
                    className="logoimg"
                    style={{ backgroundImage: `url(${kube})` }}
                  ></div>
                  <p className="logolabel" style={{ fontSize: `small` }}>
                    Kubernetes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default About;
