import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './Carasoul.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselComponent({ images }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} className="carousel-photo" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

CarouselComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CarouselComponent;
